.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background-color: #05273e;
  color: white;
  min-height: 15vh;
  max-height: 15vh;
  box-sizing: border-box;
}

.sidebar-toggle {
  font-size: 1.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 1rem;
}

.route-name {
  font-size: 1.5vw;
  margin-left: 2vw;
}

.navbar-controls {
  display: flex;
  align-items: center;
  gap: 1vw;
  margin-right: 2vw;
}

.switch {
  font-size: 2rem; /* Cambia a una unidad relativa */
  position: relative;
  display: inline-block;
  width: 10vw; /* Cambia a una unidad relativa */
  height: 8vh; /* Cambia a una unidad relativa */
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0d3c62;
  transition: 0.4s;
  border-radius: 34rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px; /* Cambia a una unidad relativa */
  overflow: hidden; /* Añadido para ocultar partes del semicírculo */
}

.slider:before {
  position: absolute;
  content: "";
  height: 100%; /* Igual a la altura del switch */
  width: 50%; /* Ancho suficiente para cubrir la mitad del switch */
  left: 0;
  top: 0;
  background-color: #ecf0f1; /* Color del semicírculo */
  transition: 0.4s;
  border-radius: 20px 0px 0px 20px; /* Crea el semicírculo */
  z-index: 1;
}

.flag {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
  width: 3vw; /* Cambia a una unidad relativa */
  height: 20vh; /* Cambia a una unidad relativa */
}

.spain {
  left: 1vw; /* Cambia a una unidad relativa */
}

.uk {
  right: 1vw; /* Cambia a una unidad relativa */
}

.switch input:checked + .slider:before {
  transform: translateX(100%); /* Cambia a 50% para que sea responsive */
  border-radius: 0px 20px 20px 0px; /* Invierte el semicírculo */
}

.switch input {
  display: none;
}

.flag svg {
  width: 100%;
  height: 100%;
}

/* Media queries para responsividad */
@media screen and (max-width: 768px) {
  .switch {
    width: 15vw; /* Cambia a una unidad relativa */
    height: 7vh; /* Cambia a una unidad relativa */
  }

  .flag {
    width: 5vw; /* Cambia a una unidad relativa */
    height: 20vh; /* Cambia a una unidad relativa */
  }

  .sidebar-toggle {
    font-size: 2rem;
    padding: 0.8rem;
  }

  .route-name {
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  .navbar-controls {
    gap: 0.8rem;
    margin-right: 1rem;
  }
}
