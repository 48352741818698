@import "../Animations.css";

/* ==========================
   ESTILOS GENERALES
   ========================== */
.card-container {
  position: relative;
  width: 100%;
  margin-bottom: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  overflow: visible;
  width: 35%;
  height: 40vh;
  max-height: 40vh;
  min-height: 40vh;
  position: relative;
  transition: all 0.8s ease;
}

.content {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.8s ease;
  position: relative;
}

.front,
.back {
  background-color: #05273e;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 3%;
  overflow: hidden;
}

/* ==========================
   PARTE FRONTAL
   ========================== */
.front {
  display: flex;
  justify-content: center;
  align-items: center;
}

.front::before {
  position: absolute;
  content: " ";
  display: block;
  width: 10vw;
  height: 160%;
  background: linear-gradient(
    90deg,
    transparent,
    #3282b8,
    #3282b8,
    #3282b8,
    #3282b8,
    transparent
  );
  animation: rotation_481 5000ms infinite linear;
}

.front-content {
  position: absolute;
  width: 99%;
  height: 99%;
  background-color: #041f32;
  border-radius: 3%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.card-image {
  width: 100%;
  height: 70%;
  object-fit: cover;
  object-position: center;
  border-radius: 3% 3% 0 0;
}

.card-title {
  margin-top: 2%;
  margin-bottom: 3%;
  font-size: 1.5vw;
  text-align: center;
}

/* ==========================
   PARTE DE ATRÁS
   ========================== */
.back {
  transform: rotateY(180deg);
  width: 60vw;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateY(180deg);
}

.back-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-description {
  font-size: 1.1vw;
  padding-top: 4%;
  padding-bottom: 2%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 80%;
}

/* ==========================
   EXPANSIÓN DE CARDS
   ========================== */
.card:hover .content {
  transform: rotateY(180deg);
}

/* ==========================
   TECNOLOGÍAS
   ========================== */
.card-technologies {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.2vw;
  background-color: transparent;
}

.tech-badge {
  background-color: #3282b8;
  color: #fff;
  padding: 0.2vw 0.6vw;
  border-radius: 20em;
  font-size: 0.9vw;
  white-space: nowrap;
  margin: 0.2em;
}

/* ==========================
   BOTONES INFERIORES
   ========================== */
.card-buttons {
  display: flex;
  justify-content: center;
  gap: 1vw;
  width: 100%;
  position: absolute;
  transition: all 0.8s ease;
  margin-top: 42vh;
}

.card:hover .card-buttons {
  width: 60%;
  left: 20%;
}

.link-button,
.photos-button {
  width: 3vw;
  height: 3vw;
  border: none;
  border-radius: 20%;
  background-color: #3282b8;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5vw;
}

.link-button:hover,
.photos-button:hover {
  background-color: #2c6f9e;
}

/* ==========================
   MODAL DE IMÁGENES DEL PROYECTO
   ========================== */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.image-modal-content {
  display: flex;
  align-items: center;
  overflow-x: auto;
  width: 90%;
  height: 80%;
  padding: 2vw 0;
  cursor: grab;
  user-select: none;
}

.image-modal-content:active {
  cursor: grabbing;
}

.image-modal-content::-webkit-scrollbar {
  display: none;
}

.image-modal img {
  max-height: 90%;
  height: 20vw;
  margin: 0 2vw;
  border-radius: 3%;
  pointer-events: none;
}

.image-modal-close {
  position: absolute;
  top: 1vw;
  right: 1vw;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 2.5vw;
  cursor: pointer;
}

.image-modal-close:hover {
  color: #ddd;
}

/* ==========================
   RESPONSIVIDAD
   ========================== */
@media (max-width: 1024px) {
  .card-container {
    margin: 2rem 4rem;
    width: 60rem;
    margin-bottom: 1rem;
  }

  .card {
    width: 100%;
    height: 20rem;
    max-height: 300rem;
    min-height: 0rem;
  }

  .card:hover {
    width: 100%;
  }

  .card:hover .content {
    transform: none;
  }

  .card.flipped .content {
    transform: rotateY(180deg);
  }

  .front::before {
    width: 40vw;
    height: 300%;
  }

  .back {
    width: 150%;
  }

  .content {
    transition: transform 0s ease;
  }

  .card-title {
    font-size: 1.5rem;
    margin: 0% 0%;
  }

  .card-description {
    font-size: 0.8rem;
  }

  .tech-badge {
    font-size: 0.8rem;
  }

  .card-buttons {
    margin-top: 21rem;
  }

  .link-button,
  .photos-button {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    transition: background-color 0s ease;
  }

  .link-button svg,
  .photos-button svg {
    width: 100%;
    height: 50%;
  }

  .image-modal-content {
    width: 90%;
    height: 100%;
    padding: 0 0;
  }

  .image-modal img {
    max-height: 100%;
    height: 60%;
    margin: 0 2%;
  }

  .image-modal-close {
    top: 1rem;
    right: 0px;
    font-size: 10vw;
  }
}
