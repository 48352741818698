/* Contenedor general */
.contact-container {
  display: flex;
  color: white;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 4vh 2vw;
  background-color: #041f32;
  overflow-x: hidden; /* Evita el scroll horizontal */
  width: 100%;
  box-sizing: border-box;
  height: 100%;
}

/* Descripción */
.contact-description {
  font-size: 1.2vw;
  margin-bottom: 3%;
  max-width: 60%;
}

/* Estilos del formulario */
.contact-form {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.form-input,
.form-textarea {
  background-color: #05273e;
  border: 0.1vw solid #3282b8;
  color: white;
  padding: 1vw;
  margin-bottom: 2%;
  border-radius: 5px;
  font-size: 1vw;
}

.form-textarea {
  height: 15vh;
  resize: none;
}

.form-button {
  padding: 0.8vw;
  background-color: #3282b8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1vw;
  transition: background-color 0.3s;
}

.form-button:hover {
  background-color: #2c6f9e;
}

/* Información de contacto */
.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 2%;
  font-size: 1vw;
}

.contact-icon {
  margin-right: 0.5vw;
}

/* Redes sociales */
.contact-socials {
  display: flex;
  gap: 20%;
  margin-top: 2%;
}

.contact-socials a {
  font-size: 2vw;
  color: white;
  transition: color 0.3s;
}

.contact-socials a:hover {
  color: #3282b8;
}

.error-message {
  font-size: 1vw;
}

.success-message {
  font-size: 1vw;
}

.form-input.error,
.form-textarea.error {
  border: 0.1vw solid red; /* Cambia el borde a rojo si hay un error */
}

/* Responsividad */
@media (max-width: 1024px) {
  .contact-title {
    font-size: 2rem;
  }

  .contact-description {
    font-size: 1rem;
  }

  .error-message {
    font-size: 1vw;
  }

  .form-input,
  .form-textarea,
  .form-button {
    font-size: 0.9rem;
  }

  .info-item {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    margin-bottom: 0.1rem;
  }

  .contact-socials a {
    font-size: 1.5rem;
  }

  .error-message {
    font-size: 0.7rem;
  }

  .success-message {
    font-size: 0.7rem;
  }
}
