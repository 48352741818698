@import "./components/Animations.css";

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #000; /* Fondo negro */
  color: lightgray;
}

.loading-screen img {
  width: 20vw; /* Tamaño del logo */
  align-self: center;
  animation: fadein 1s ease-in-out; /* Animación del logo */
}

.loading-screen h2 {
  text-align: center;
  animation: fadein 1s ease-in-out; /* Animación del logo */
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;
  font-size: 3vw;
}

@media (max-width: 1024px) {
  .loading-screen img {
    width: 13em; /* Tamaño del logo */
    animation: fadein 1s ease-in-out; /* Animación del logo */
  }

  .loading-screen h2 {
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    line-height: 1.2;
    font-size: 1.8em;
    animation: fadein 1s ease-in-out; /* Animación del logo */
  }
}
