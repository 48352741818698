.projects-container {
  padding: 4vh 2vw;
  background-color: #041f32;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
}

.projects-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.project-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10vh;
  opacity: 0;
  transform: translateX(-20%);
  transition: opacity 0.5s ease, transform 0.5s ease;
  width: 100%;
}

.project-section.visible {
  opacity: 1;
  transform: translateX(0);
}
