@import "Animations.css";

.about-container {
  padding: 4vh 2vw;
  background-color: #041f32;
  overflow-x: hidden; /* Evita el scroll horizontal */
  width: 100%;
  box-sizing: border-box;
}

.about-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10vh;
  opacity: 0;
  transform: translateX(-20%);
  transition: opacity 0.5s ease, transform 0.5s ease;
  width: 90%;
}

.about-section.right {
  flex-direction: row-reverse;
  transform: translateX(20%);
}

.about-section.visible {
  opacity: 1;
  transform: translateX(0);
}

.content-card {
  box-sizing: border-box;
  width: 80%;
  background-color: #041f32;
  border: 0.5vw solid #14415f;
  border-radius: 2vw;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 2vw;
  transition: all 0.5s, border-color 0s;
}

.content-card:hover {
  border: 0.7vw solid #3282b8;
  transform: scale(1.05);
}

.content-card:active {
  transform: scale(1.08);
  animation: changeColor 1.5s infinite;
}

.icon-container {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
  color: #3282b8;
  transition: all 0.3s ease;
}

.content-card:hover + .icon-container {
  transform: scale(1.1);
}

.content-card:active + .icon-container {
  animation: spin 4s linear infinite;
}

.about-title {
  font-size: 3vw;
  color: #3282b8;
  margin-bottom: 2vh;
  font-style: italic;
  margin-top: 0;
  font-weight: bolder;
}

.about-description {
  font-size: 1.2vw;
  color: white;
  line-height: 1.6;
  font-style: oblique;
}

.cv-button-fixed {
  position: fixed;
  top: 16vh;
  right: 2vw;
  padding: 0 1.5vw;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4vw;
  font-weight: 500;
  /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
  font-family: inherit;
  z-index: 1;
  overflow: hidden;
  height: 4vw;
  background-color: #3282b8;
  color: #fff;
  border: none;
  animation: bounce 4s ease-in-out;
  animation-fill-mode: forwards;
}

.cv-button-fixed:hover::before {
  transform: scaleX(1);
}

.cv-button-content {
  position: relative;
  z-index: 1;
  font-size: 1.1vw;
  text-align: center;
}

.cv-button-fixed::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  width: 100%;
  height: inherit;
  border-radius: inherit;
  background: linear-gradient(82.3deg, #3282b8 10.8%, #14415f 94.3%);
  transition: all 0.475s;
}

@media (max-width: 1024px) {
  .about-section {
    margin-bottom: 3vh;
    width: 100%;
    min-width: 300px;
  }
  .content-card {
    margin-bottom: 0vh;
    min-height: 200px;
  }

  .icon-container {
    margin-bottom: 0vh;
    font-size: 10vw;
  }

  .about-title {
    font-size: 6vw;
  }

  .about-description {
    font-size: 4vw;
  }

  .cv-button-fixed {
    padding: 1.5em 5vw;
    height: 3.5vw;
    box-sizing: border-box;
    border-radius: 2vw;
    text-align: center;
  }
  .cv-button-content {
    font-size: 2.6vw;
  }
  .cv-button-fixed::before {
    height: 100%;
  }
}
