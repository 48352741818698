/* mainContent container */
.mainContent-container {
  display: flex;
  transition: margin-left 0.3s ease; /* Transición suave */
}

/* Main section (contenido principal) */
.main-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  background-color: #1b262c;
  height: 100vh; /* Asegura que ocupe el 100% de la altura de la ventana */
  overflow-y: auto; /* Permitir scroll solo en la main-section */
}

/* Cuando la sidebar está visible, no desplazamos el contenido */
.mainContent-container.sidebar-visible .main-section {
  margin-left: auto;
}

/* Cuando la sidebar está oculta, el contenido se desplaza a la izq para tapar el hueco de la sidebar */
.mainContent-container.sidebar-hidden .main-section {
  margin-left: -100vw;
}
