@import "Animations.css";

/* content.css */
.home-content {
  background-color: #041f32;
  color: white;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
  box-sizing: border-box;
  height: 100vh; /* Para asegurarse que ocupa el 100% de la altura */
  overflow-y: auto; /* Permitir el scroll dentro del contenido si es necesario */
  transition: transform 0.3s ease-in-out;
}

.large-logo {
  width: 30%;
  margin-bottom: 2em;
  margin-top: 2em;
  animation: fadeInDown 1s ease-in-out;
}

.divider {
  width: 80%;
  margin: 1em 0;
}
.motto {
  font-size: 1.2vw; /* Escala según el tamaño base del documento */
  text-align: center;
  width: 70%;
  margin-bottom: 5em;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;
  font-style: oblique;
  animation: fadeInUp 1s ease-in-out; /* Animación */
}

@media (max-width: 1024px) {
  .home-content {
    justify-content: flex-start;
  }
  .large-logo {
    width: 60%;
    margin-top: 5em;
  }

  .divider {
    width: 80%;
    margin: 1em 0;
  }
  .motto {
    font-size: 4vw; /* Escala según el tamaño base del documento */
    text-align: center;
    width: 70%;
  }
}
