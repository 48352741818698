/* Sidebar container */
.sidebar {
  display: flex;
  width: 25vw;
  background-color: #05273e;
  padding: 2%;
  height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  overflow-y: auto; /* Agregar scroll si el contenido excede la altura */
  scrollbar-width: none; /* Ocultar el scroll en Firefox */
  -ms-overflow-style: none; /* Ocultar el scroll en IE y Edge */
  z-index: 2;
}

.sidebar::-webkit-scrollbar {
  display: none; /* Ocultar el scroll en Chrome y Safari */
}

.sidebar.hidden {
  transform: translateX(-100%);
}

.sidebar.show {
  transform: translateX(0);
}

/* Botón de cierre (sólo visible en móviles) */
.close-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 2em;
  cursor: pointer;
}

/* Logo */
.logo {
  width: 12.5vw;
  margin-top: 3%;
  cursor: pointer;
}

/* Nombre (tamaño responsivo usando em) */
.sidebar-name {
  color: white;
  font-size: 2vw; /* Escala con el tamaño del contenedor */
  text-align: center;
  width: 80%;
  white-space: normal;
  word-wrap: break-word;
  margin-top: 1em;
  margin-bottom: 0.5em;
  line-height: 1.2;
  cursor: pointer;
  font-weight: bold;
  font-style: italic;
}

/* Descripción (tamaño responsivo usando rem) */
.sidebar-description {
  color: white;
  font-size: 1.2vw; /* Escala según el tamaño base del documento */
  text-align: center;
  width: 70%;
  margin-bottom: 3em;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;
  font-style: oblique;
}

.button {
  width: 80%;
  min-height: 3em;
  height: 100%;
  position: relative;
  height: 3.5em;
  border: 0.3em ridge #3282b8;
  outline: none;
  background-color: transparent;
  color: white;
  transition: 1s;
  border-radius: 0.3em;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 1.5em;
  align-items: center;
  font-size: 1.2vw;
  font-weight: 600;
  z-index: 1;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.button::after {
  content: "";
  position: absolute;
  top: -20%;
  left: 3%;
  width: 94%;
  height: 40%;
  background-color: #05273e;
  transition: 0.5s;
  transform-origin: center;
}

.button::before {
  content: "";
  position: absolute;
  top: 90%;
  left: 3%;
  width: 94%;
  height: 40%;
  background-color: #05273e;
  transition: 0.5s;
  transform-origin: center;
}

@media (min-width: 3840px) {
  .button::after {
    top: -20%;
  }
}

.button:hover::before,
.button:hover::after {
  transform: scale(0);
}

.button:hover {
  box-shadow: inset 0px 0px 25px #3282b8;
  color: white;
}

/* Icono dentro del botón */
.button .icon {
  margin-right: 3%;
  font-size: 1.2em; /* tamaño de icono relativo al tamaño de fuente */
}

/* Ajustes para móviles */
@media (max-width: 1024px) {
  .sidebar {
    width: 100vw; /* Ocupar todo el ancho en moviles */
    min-width: 100vw; /* Aseguramos que nunca sea menos del 100% */
    max-width: 100vw; /* Aseguramos que nunca sea más del 100% */
  }

  .logo {
    margin-top: 20%;
    width: 40vw; /* Ajustamos el tamaño del logo para pantallas pequeñas */
  }

  .sidebar-name {
    font-size: 5vw; /* Ajustamos el tamaño del texto en móviles */
  }

  .sidebar-description {
    font-size: 4vw; /* Ajustamos el tamaño de la descripción en móviles */
  }

  .button {
    font-size: 4vw; /* Ajustamos el tamaño de los botones en móviles */
    height: 3em; /* Ajustamos la altura de los botones en móviles */
  }

  .button::after {
    height: 30%;
  }
}
